import './App.scss';

import React, { Suspense, lazy } from "react"

import { Router } from "@reach/router"

//import HomePage from './home/HomePage';
const HomePage = lazy(() => import('./home/HomePage'));

//import TravelbookPage from './travelbook/TravelbookPage';
const TravelbookPage = lazy(() => import('./travelbook/TravelbookPage'));

function App() {

  return (
    <div>
      <Suspense fallback={<div>Chargement...</div>}>
        <Router>
          <TravelbookPage path="/:urlTravelbookSlug/:urlStepSlug" />
          <TravelbookPage path="/:urlTravelbookSlug" />
          <HomePage path="/" />
        </Router>
      </Suspense>
    </div>
  );

}

export default App;

