import { SET_STARTSTEP } from '../actions/index'

// Etape de départ du carnet
const startStepReducer = (state = '', action) => {
    if (action.type === SET_STARTSTEP) {
        return action.payload
    }
    return state
}

export default startStepReducer