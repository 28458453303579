import { SET_JWT } from '../actions/index'

// json web token
const jwtReducer = (state = '', action) => {
    if (action.type === SET_JWT) {
        return action.payload
    }
    return state
}

export default jwtReducer