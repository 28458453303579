import { SET_ISACTIVEDU } from '../actions/index'

// Menu Drop Up
const isActiveDUReducer = (state = false, action) => {
    if (action.type === SET_ISACTIVEDU) {
        return action.payload
    }
    return state
}

export default isActiveDUReducer