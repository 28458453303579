import { SET_MENUNAME } from '../actions/index'

// Nom du menu à dérouler
const menuNameReducer = (state = '', action) => {
    if (action.type === SET_MENUNAME) {
        return action.payload
    }
    return state
}

export default menuNameReducer